import * as React from "react";
import { Link } from "gatsby";
import linkIcon from '../images/link.png';

const introStyles = {
  margin: '0 auto',
  maxWidth: '700px',
  marginBottom: "4rem",
}

const paragraphStyles = {
  marginBottom: "1.8rem",
  fontSize: '1rem'
}

const imageStyle = {
  border: "1px solid #f0f0f0",
  boxShadow: '0 1px 1px rgba(0, 0, 0, 0.15)',
  maxWidth: '100%',
}



// markup
const Project = (props) => {

    const imageGridStyle = {
        margin: '0 auto', 
        maxWidth: props.images.length > 2 ? '1200px': '1000px', 
        display: 'grid', 
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        gap: '20px', 
        gridAutoFlow: 'dense',
        justifyContent: 'stretch',
        padding: '15px',
      }

  return (
    <main>
      <title>{props.title}</title>
      <div style={introStyles}>
        <Link to="/">⇽ Home </Link>
        <h1 style={{ fontWeight: 'normal' }}>
        {props.title}
        </h1>
        <p style={paragraphStyles}>
        {props.description}&nbsp;&nbsp;<a href={props.link} target="_blank">Visit site</a>
        </p>
      </div>
      <div style={{...imageGridStyle, margin: '0 auto'}}>
          {props.images.map((src, i) => <img src={src} style={{...imageStyle, gridColumn: i === 0 ? 'span 2' : 'span 1', gridRow: i === 0 ? 'span 2': 'span 1'}}/>)}
        </div>
    </main>
  )
}

export default Project
