import * as React from "react";
import image from '../../images/election.png';
import image2 from '../../images/election2.png';
import Project from '../../Layouts/project';


// markup
const BC = () => {
  return (
    <Project 
      title="Election results 2022" 
      images={[image2, image]}
      description="Built customizable maps and visuals used by AP customers (including NPR, the New Yorker, Fox, PBS and many more) to display the results of primaries and the general election in 2022." 
      />)
}

export default BC
